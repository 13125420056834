import React, {useContext, useEffect, useMemo, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {requestPurchaseTicketFn} from "../../apis";
import {IAccount, IRequestPurchaseTicket} from "../../apis/types";
import ButtonComponent from "../../components/button/button";
import PaymentService from "../../components/payment-service/payment-service";
import ProcessBar from "../../components/process-bar";
import ThankYou from "../../components/thank-you/thank-you";
import {PhoneCountry} from "../../constants/phone-country";
import {TicketContext} from "../../contexts/ticket-context";
import {useLoading} from "../../hooks/useLoading";
import useToast, {EToast} from "../../hooks/useToast";
import handleServiceError, {convertPhoneNumber, fixDecimals,} from "../../utils/helper";
import BillingInformation, {IError} from "../billing-info/billing-info";
import Orders from "../orders/orders";
import Ticket from "../tickets/tickets";
import parse from "html-react-parser";
import "./content.scss";
import ct from "countries-and-timezones";
import {useTranslation} from "react-i18next";
import {validateEmail, validatePhone} from "./validate";
import {parsePhoneNumber} from "libphonenumber-js";
import {setEmailLocal} from "../../utils/localStorage";
import useMediaQuery from "../../hooks/useMediaQuery";
import Addons from "../addons/addons";
import moment from "moment";
import QuestionsInformation from "../questions/questions";
import {ThemeContext} from "../../contexts/theme-context";
import TicketClose from "../../components/TicketClose/TicketClose";
import Events from "../events/events";
import {ETheme} from "../../constants/theme";
import {ETicketType} from "../../constants/ticket-type";
import {EventContext, IEventObject} from "../../contexts/event-context";
import {EEventType} from "../../constants/event-type";

export interface IContentProps { }

const initialStepData = ["tickets", "checkout", "paymentService", "thankyou"];

const stepQuestionsInit = [
  [
    {
      question: "Are you sharing a tent?",
      type: "yesNo",
      answer: null,
      optionData: [
        { label: "Yes", value: "Yes" },
        { label: "No", value: "No" },
      ],
    },
    {
      question: "Is it a partner or friend?",
      type: "selection",
      placeholder: "Selection",
      answer: null,
      optionData: [
        { label: "Partner", value: "Partner" },
        { label: "Friend", value: "Friend" },
      ],
    },
    {
      question: "What is their name?",
      type: "textInput",
      placeholder: "Full name",
      answer: "",
    },
    {
      type: "text",
      answer: "If not, we will match you with someone we think you’ll like.",
    },
  ],

  [
    {
      question:
        "Do you have any serious medical needs that we should know about?",
      type: "areaInput",
      answer: "",
    },
  ],

  [
    {
      question:
        "Donations will be matched! Select one of OUR five impact organizations and help us reach our goal! Every bit counts.",
      type: "selection",
      placeholder: "Select organization",
      answer: null,
      optionData: [
        { label: "Despri-Haiti", value: "Despri-Haiti" },
        { label: "Global Coralition", value: "Global Coralition" },
        { label: "Charity Water", value: "Charity Water" },
        { label: "No Hungry Children", value: "No Hungry Children" },
        { label: "Dreamweaver Foundation", value: "Dreamweaver Foundation" },
      ],
    },
  ],

  [
    {
      question:
        "I agree with this NDA & Liability Waiver before purchasing your ticket.",
      type: "checkbox",
      answer: true,
      questionComponent: (
        <p>
          I agree with this NDA &{" "}
          <a
            href="https://cdn.chi.app/Liabiltity__NDA.pdf"
            target="blank"
            className="linkTerm"
          >
            Liability
          </a>{" "}
          Waiver before purchasing your ticket.
        </p>
      ),
    },
  ],
];
export default function Content(props: IContentProps) {
  const [step, setStep] = useState<number>(0);
  const { tickets, currency, event, serviceCostMaxFee } = useContext(TicketContext);
  const { loadedEvents } = useContext(EventContext);
  const { theme } = useContext(ThemeContext);
  const [stepQuestions, setStepQuestion] = useState(stepQuestionsInit);

  const handleChangeValue = (
    blockIndex: number,
    itemIndex: number,
    value: any
  ) => {
    // console.log("ALOALOALO: ", blockIndex, itemIndex, value);
    const newStepQuestions = [...stepQuestions];
    newStepQuestions[blockIndex][itemIndex]["answer"] = value;
    setStepQuestion(newStepQuestions);
  };

  const [isAddon, setIsAddon] = useState<boolean>(
    tickets.some((ticket) => ticket.isAddOn)
  );
  const addToast = useToast();
  const [link, setLink] = useState<string>("");
  const [searchParams] = useSearchParams();
  // console.log("DUONG BINH TRAN: ", searchParams);
  const { showLoading, hideLoading } = useLoading();
  const [account, setAccount] = useState<IAccount>({ email: "", phone: "" });
  const [error, setError] = useState<IError>({
    email: "",
    phone: "",
  });
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const countryCode = ct.getTimezone(timeZone)?.countries[0];
  const { t } = useTranslation();
  const navigate = useNavigate();
  const matches = useMediaQuery("(max-width: 768px)");

  const [isQuestion, setIsQuestion] = useState<boolean>(false);

  const [stepData, setStepData] = useState<string[]>(initialStepData);

  const checkEventIsEnded = useMemo(() => {
    if (event) {
      const current = moment().valueOf();
      const endDate = moment(event.endDate).valueOf();
      if (current > endDate) return true;
    }
    return false;
  }, [event]);

  useEffect(() => {
    if (tickets) {
      setIsAddon(tickets.some((ticket) => ticket.isAddOn));
    }
    if (theme === ETheme.OR) {
      setIsQuestion(true);
    }

    if (tickets) {
      let newStepData = [...initialStepData];
      if (theme === ETheme.OR && tickets.some((ticket) => ticket.isAddOn)) {
        const elementsToInsert = [ETicketType.ADDONS, ETicketType.QUESTION];
        newStepData.splice(1, 0, ...elementsToInsert);
      } else if (theme === ETheme.OR && !tickets.some((ticket) => ticket.isAddOn)) {
        newStepData.splice(1, 0, ETicketType.QUESTION);
      } else if (theme !== ETheme.OR && tickets.some((ticket) => ticket.isAddOn)) {
        newStepData.splice(1, 0, ETicketType.ADDONS);
      }
      setStepData(newStepData);
    }
  }, [tickets, theme]);

  useEffect(() => {
    if (countryCode) {
      const phoneCountry = PhoneCountry.find(
        (item) => item.code === countryCode
      );
      phoneCountry && setAccount({ ...account, phone: phoneCountry.phone });
    }
  }, [countryCode]);

  const renderPaymentService = useMemo(() => {
    return <PaymentService />;
  }, [t]);

  const renderThankyou = useMemo(() => {
    return <ThankYou />;
  }, [t]);

  const onChangeAccount = (acc: IAccount) => {
    setError({ email: "", phone: "" });
    setAccount(acc);
  };

  const renderContent = useMemo(() => {
    switch (stepData[step]) {
      case "tickets":
        return <Ticket />;
      case "addons":
        return <Addons />;
      case "question":
        return (
          <QuestionsInformation
            stepQuestions={stepQuestions}
            handleChangeValueQuestion={handleChangeValue}
          />
        );
      case "checkout":
        return (
          <>
            <BillingInformation
              error={error}
              account={account}
              setAccount={onChangeAccount}
            />
            <Orders />
          </>
        );
      case "paymentService":
        return renderPaymentService;
      case "thankyou":
        return renderThankyou;
      default:
        break;
    }
  }, [step, account, error, t, isAddon, stepQuestions]);

  const renderTextBtn = useMemo(() => {
    const totalPay = tickets.reduce(
      (acc, item) => acc + item.order * item.price,
      0
    );
    const formatTotal = fixDecimals(totalPay * (1 + serviceCostMaxFee));

    if (stepData[step] === "tickets") {
      if (stepData[step + 1] === "addons") {
        return t("btn.addons");
      } else if (stepData[step + 1] === "question") {
        return t("btn.questions");
      } else if (stepData[step + 1] === "checkout") {
        return t("btn.checkout");
      }
    }

    if (stepData[step] === "addons") {
      if (stepData[step + 1] === "question") {
        return t("btn.questions");
      } else if (stepData[step + 1] === "checkout") {
        return t("btn.checkout");
      }
    }
    if (stepData[step] === "question") {
      return t("btn.checkout");
    }
    if (stepData[step] === "checkout") {
      return parse(
        `${t(
          "btn.pay"
        )} <span className="concurrency">${currency}</span> ${formatTotal}`
      ) as string;
    }
  }, [step, tickets, t, isAddon]);

  const onChangeStep = (value?: number) => {
    // if (step === 0) {
    //   if (!ticketSelect ) return true;
    // }
    // if(stepData[step] === 'question') {
    //   const hasUnansweredQuestions = customArrayQues().some((obj) => !obj.value);
    //   if(hasUnansweredQuestions) return true
    // }

    const ticketSelected = tickets.find((item) => item.count > 0);
    if (value || value === 0) {
      if (stepData[value] !== "tickets") {
        if (!ticketSelected)
          return addToast(EToast.ERROR, t("toast.validate.ticket"));
      }
      if (
        stepData[value] === "checkout" &&
        stepData[value - 1] === "question"
      ) {
        const hasUnansweredQuestions = customArrayQues()
          .filter(
            (item) =>
              item.question !==
              "Do you have any serious medical needs that we should know about?"
          )
          .some((obj) => !obj.answer);
        if (hasUnansweredQuestions)
          return addToast(
            EToast.ERROR,
            "Please complete your answers before checking out"
          );
      }
      setError({ email: "", phone: "" });
      setStep(value);
      return;
    }
    const nextStep = step + 1;
    // if (nextStep === 1) {
    //   if (!ticketSelected)
    //     return addToast(EToast.ERROR, t("toast.validate.ticket"));
    // }
    if (stepData[nextStep] === "paymentService") {
      const isValidate = onValidateAccount();
      if (isValidate) {
        onLocketTicket();
      }
      return;
    }
    setError({ email: "", phone: "" });
    setStep(nextStep);
  };

  const onValidateAccount = () => {
    if (!account.email && !account.phone) {
      setError({ email: "", phone: t("toast.validate.require") });
      return false;
    }
    if (!account.email) {
      setError({
        phone: "",
        email: t("toast.validate.emailRequire"),
      });
      return false;
    }
    if (!account.phone) {
      setError({
        phone: t("toast.validate.phoneRequire"),
        email: "",
      });
      return false;
    }
    if (account.email) {
      const test = validateEmail(account.email);
      if (!test) {
        setError({
          phone: "",
          email: t("toast.validate.inValidEmail"),
        });
        return false;
      }
    }
    if (account.phone) {
      const phoneNumber = convertPhoneNumber(account.phone);
      const test = validatePhone(phoneNumber);
      if (!test) {
        setError({
          phone: t("toast.validate.inValidPhone"),
          email: "",
        });
        return false;
      }
      try {
        parsePhoneNumber(account.phone);
      } catch (error: any) {
        setError({
          phone: t(
            `${error.message === "INVALID_COUNTRY"
              ? t("toast.validate.inValidCountry")
              : t("toast.validate.inValidPhone")
            }`
          ),
          email: "",
        });
        return false;
      }
    }
    return true;
  };

  const customArrayQues = () => {
    let outputArray = stepQuestionsInit
      .flat()
      .filter((item) => item.type !== "text");
    let answersYesNo = outputArray.find(
      (item) => item.question === "Are you sharing a tent?"
    )?.answer;

    let outputQA = outputArray;
    if (answersYesNo === "No") {
      outputQA = outputQA.filter(
        (item) =>
          item.question !== "Is it a partner or friend?" &&
          item.question !== "What is their name?"
      );
    }
    const payloadArrayQA = outputQA.map((questionObj) => {
      const { question, answer } = questionObj;
      return {
        question,
        answer,
      };
    });

    return payloadArrayQA;
  };

  const onLocketTicket = async () => {
    const ticketInfoList = tickets
      .filter((item) => item.order > 0)
      .map((item) => ({
        ticketDefinitionId: item._id,
        amount: item.order,
      }));

    const parsePhone = parsePhoneNumber(account.phone);
    const body: IRequestPurchaseTicket = {
      tickets: ticketInfoList,
      email: account.email,
      phone: parsePhone.number,
      eventId: event._id,
      answers: isQuestion ? customArrayQues() : [],
    };
    setEmailLocal(account.email);
    try {
      showLoading();
      const data = await requestPurchaseTicketFn(body);
      setLink(data.url);
    } catch (error) {
      handleServiceError(error);
    } finally {
      hideLoading();
    }
  };

  // const disableBtn = useMemo(() => {
  //   const ticketSelect = tickets.some((item) => item.count > 0);
  //   const hasUnansweredQuestions = customArrayQues().some((obj) => !obj.value);

  //   if (stepData[step + 1] === "checkout") {
  //     // console.log("CHECK IS Ticket: ", !ticketSelect);
  //     return !ticketSelect || hasUnansweredQuestions;
  //   }

  //   return false;
  // }, [tickets, step, stepQuestions]);

  const disableBtn = useMemo(() => {
    const ticketSelect = tickets.find((item) => item.count > 0);
    if (step === 0) {
      if (!ticketSelect) return true;
    }
    if (stepData[step] === "question") {
      const hasUnansweredQuestions = customArrayQues()
        .filter(
          (item) =>
            item.question !==
            "Do you have any serious medical needs that we should know about?"
        )
        .some((obj) => !obj.answer);
      if (hasUnansweredQuestions) return true;
    }
    return false;
  }, [tickets, step, stepQuestions, isQuestion]);

  useEffect(() => {
    if (link) {
      window.location.assign(link);
      setStep(stepData.indexOf("paymentService"));
    }
    setLink("");
  }, [link]);

  useEffect(() => {
    const paramsAsObject = Object.fromEntries([...(searchParams as any)]);
    if (paramsAsObject.transactionId) {
      // setStep(isAddon ? 4 : 3);
      setStep(stepData.indexOf("thankyou"));
    }
  }, [searchParams, theme, stepData]);

  useEffect(() => {
    const orderTicket = tickets.find((item) => item.order > 0);
    const shouldResetStep = !orderTicket && stepData[step] === "checkout";
    // !orderTicket && ((isAddon && step === 2) || (!isAddon && step === 1));
    if (shouldResetStep) {
      setStep(0);
    }
  }, [tickets]);

  return (
    <div
      className={`content ${(stepData[step] === "paymentService" ||
        stepData[step] === "thankyou" || checkEventIsEnded) &&
        "border-top"
        }`}
    >
      {(stepData[step] !== "paymentService" && stepData[step] !== "thankyou" && !checkEventIsEnded && !loadedEvents) && (
        <div className="content-progress">
          <ProcessBar
            step={step}
            setStep={onChangeStep}
            isAddon={isAddon}
            stepData={stepData}
          />
        </div>
      )}
      <div
        className="content-title"
        style={{
          padding: stepData[step] === "paymentService" ? "24px 0 0 0" : loadedEvents ? "0px 0px 20px" : "",
        }}
      >
        {loadedEvents ? <Events /> : !checkEventIsEnded ? <> {renderContent}</> : <TicketClose />}
      </div>
      {stepData[step] !== "paymentService" && stepData[step] !== "thankyou" && !checkEventIsEnded && !loadedEvents && (
        <ButtonComponent
          disabled={disableBtn}
          text={renderTextBtn as string}
          onClick={() => onChangeStep()}
        />
      )}
    </div>
  );
}

// const onChangeStep = (value?: number) => {
// if (step === 3) {
//   const eventId = event._id;
//   if (eventId) {
//     navigate(`/${eventId}`);
//     setStep(0);
//     return;
//   }
// }

// if (value || value === 0) {
//   if (value === 1 || value === 2) {
//     const ticketSelected = tickets.find((item) => item.count > 0);
//     if (!ticketSelected)
//       return addToast(EToast.ERROR, t("toast.validate.ticket"));
//   }
//   setError({ email: "", phone: "" });
//   setStep(value);
//   return;
// }
// const nextStep = step + 1;
// if (nextStep === 1) {
//   const ticketSelected = tickets.find((item) => item.count > 0);
//   if (!ticketSelected)
//     return addToast(EToast.ERROR, t("toast.validate.ticket"));
// }
// if (isAddon) {
//   if (nextStep === 2) {
//   }
//   if (nextStep === 3) {
//     console.log("ALAOALOAALO");
//     const isValidate = onValidateAccount();
//     if (isValidate) {
//       onLocketTicket();
//     }
//     return;
//   }
// } else {
//   if (nextStep === 2) {
//     const isValidate = onValidateAccount();
//     if (isValidate) {
//       onLocketTicket();
//     }
//     return;
//   }
// }
// setError({ email: "", phone: "" });
// console.log("!!!!!!!!!: ", nextStep);
// setStep(nextStep);
// if (step === 3) {
//   const eventId = event._id;
//   if (eventId) {
//     navigate(`/${eventId}`);
//     setStep(0);
//     return;
//   }
// }
// };

// const renderTextBtn = useMemo(() => {
// if (isAddon) {
//   if (step === 0) return t("btn.addons");
//   if (step === 1) return t("btn.checkout");
//   if (step === 2) {
//     const totalPay = tickets.reduce(
//       (acc, item) => acc + item.order * item.price,
//       0
//     );
//     const formatTotal = fixDecimals(totalPay * (1 + SERVICE_FEE));
//     return parse(
//       `${t(
//         "btn.pay"
//       )} <span className="concurrency">${currency}</span> ${formatTotal}`
//     ) as string;
//   } else return "";
// } else {
//   if (step === 0) return t("btn.checkout");
//   if (step === 1) {
//     const totalPay = tickets.reduce(
//       (acc, item) => acc + item.order * item.price,
//       0
//     );
//     const formatTotal = fixDecimals(totalPay * (1 + SERVICE_FEE));
//     return parse(
//       `${t(
//         "btn.pay"
//       )} <span className="concurrency">${currency}</span> ${formatTotal}`
//     ) as string;
//   } else return "";
// }
// if (step === 3) return t("btn.goHome");
// }, [step, tickets, t, isAddon]);

// const renderContent = useMemo(() => {
// if (step === 0) {
//   return <Ticket />;
// }
// if (isAddon) {
//   if (step === 1) {
//     return <Addons />;
//   }
//   if (step === 2)
//     return (
//       <>
//         <BillingInformation
//           error={error}
//           account={account}
//           setAccount={onChangeAccount}
//         />
//         <Orders />
//       </>
//     );
//   if (step === 3) return renderPaymentService;
//   if (step === 4) return renderThankyou;
// } else {
//   if (step === 1)
//     return (
//       <>
//         <BillingInformation
//           error={error}
//           account={account}
//           setAccount={onChangeAccount}
//         />
//         <Orders />
//       </>
//     );
//   if (step === 2) return renderPaymentService;
//   if (step === 3) return renderThankyou;
// }
// }, [step, account, error, t, isAddon]);

// useEffect(() => {
//   if (step === 2 && link && isAddon) {
//     const linkURL = document.createElement("a");
//     linkURL.href = link;
//     if (!matches) linkURL.setAttribute("target", "_blank");
//     document.body.appendChild(linkURL);
//     linkURL.click();
//     setLink("");
//     setStep(3);
//   }
//   if (step === 1 && link && !isAddon) {
//     const linkURL = document.createElement("a");
//     linkURL.href = link;
//     if (!matches) linkURL.setAttribute("target", "_blank");
//     document.body.appendChild(linkURL);
//     linkURL.click();
//     setLink("");
//     setStep(2);
//   }
// }, [link, isAddon]);

// useEffect(() => {
//   const orderTicket = tickets.find((item) => item.order > 0);
//   if (!orderTicket && isAddon && step === 2) setStep(0);
//   if (!orderTicket &&!isAddon && step === 1 ) setStep(0);
// }, [tickets]);

// useEffect(() => {
//   if (link) {
//     const linkURL = document.createElement("a");
//     linkURL.href = link;
//     if (!matches) {
//       linkURL.setAttribute("target", "_blank");
//     }
//     document.body.appendChild(linkURL);
//     linkURL.click();
//     setLink("");
//     setStep(stepData.indexOf("paymentService"));
//     // if (isAddon) {
//     //   setStep(3);
//     // } else {
//     //   setStep(2);
//     // }
//   }
// }, [link]);

// useEffect(() => {
//   const current = moment().valueOf();
//   const endDate = moment(event.endDate).valueOf();
//   if (current > endDate) {
//     // setStep(isAddon ? 4 : 3);
//     setStep(stepData.indexOf("thankyou"));
//   }
// }, [event]);