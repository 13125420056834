import moment from "moment";
import React, { useCallback, useContext, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import Dropdown from "../components/dropdown/dropdown";
import Source from "../constants/source";
import { ETheme } from "../constants/theme";
import { ThemeContext } from "../contexts/theme-context";
import { TicketContext } from "../contexts/ticket-context";
import { setUrl, getUrl } from "../utils/localStorage";
import Content from "./content/content";
import Header from "./header/header";
import "./style.scss";
import {defaultEvents, EventContext, EventTypeParam} from "../contexts/event-context";

export interface IMainProps {}

export default function Main(props: IMainProps) {
  const { theme } = useContext(ThemeContext);
  const { onGetTicket, event, termconditionUrl } = useContext(TicketContext);
  const { onGetEvents, loadedEvents, setLoadedEvents } = useContext(EventContext);
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  useEffect(() => {
    const paramsAsObject = Object.fromEntries([...(searchParams as any)]);
    if (!paramsAsObject.transactionId)
      getTicketByUrl();
  }, []);

  const getFullUrl = () => {
    let url = window.location.href;
    const obj = new URL(url);
    obj.search = "";
    obj.hash = "";
    url = obj.toString();
    const lastString = url.slice(-1);
    if (lastString === "/") url = url.substring(0, url.length - 1);
    return url;
  };

  const getTicketByUrl = async () => {
    const url = getFullUrl();
    setUrl(url);
    try {
      await onGetTicket(url);
    } catch (error: any) {
      if (error.response.data.Error == "Event not found ") {
        await onGetEvents({
          date: EventTypeParam.upcoming,
          limit: defaultEvents.limit,
          page: defaultEvents.page
        });
        if (setLoadedEvents) setLoadedEvents(true);
      }
    }
  };

  useEffect(() => {
    const paramsAsObject = Object.fromEntries([...(searchParams as any)]);
    if (paramsAsObject.transactionId) {
      const url = getUrl();
      if (url) onGetTicket(url);
    }
  }, [searchParams]);

  const onOpenTermConditions = useCallback(() => {
    const linkURL = document.createElement("a");
    linkURL.href = Source.termConditional;
    linkURL.setAttribute("target", "_blank");
    document.body.appendChild(linkURL);
    linkURL.click();
  }, []);

  return (
    <>
      {event._id && (
        <Helmet>
          <meta charSet="utf-8" />
          <link rel="canonical" href="%PUBLIC_URL%/favicon.ico" />
          <meta
            name="description"
            content={`Ticket sale for ${event.name} on ${moment(
              event.startDate
            ).format("MMMM DD, YYYY")} ${t("powered")} EventCHI`}
          />
        </Helmet>
      )}
      {(event._id || loadedEvents) && (
        <>
          <Header />
          <Content />
          <div className="footer">
            <div className="termsCondition">
              <div className={`powered ${theme === ETheme.DARK && "powered-dark"}`}>
                <a
                  href={
                    termconditionUrl ? termconditionUrl : "https://cdn.chi.app/termcondition.pdf"
                  }
                  target="blank"
                  style={{ textDecoration: "none" }}
                >
                  Terms & conditions
                </a>
              </div>
              <div
                  className={`powered ${theme === ETheme.DARK && "powered-dark"}`}
                  onClick={() => onOpenTermConditions()}
              >
                {t("powered")} <span>EventCHI</span>
              </div>
            </div>
            {/* <div
              className={`powered ${theme === ETheme.DARK && "powered-dark"}`}
              onClick={() => onOpenTermConditions()}
            >
              {t("powered")} <span>EventCHI</span>
            </div> */}
            <Dropdown />
          </div>
        </>
      )}
    </>
  );
}
