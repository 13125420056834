import {createContext, Dispatch, PropsWithChildren, SetStateAction, useEffect, useState} from "react";
import {IEvent, IRequestUpcomingEvents} from "../apis/types";
import { useLoading } from "../hooks/useLoading";
import {fetchEventsByDate} from "../apis";
import handleServiceError from "../utils/helper";
import {EEventType} from "../constants/event-type";

export interface IEventContext {
  onGetEvents: (body: IRequestUpcomingEvents) => void,
  onChangeActiveTab: (tab: string) => void,
  upcomingEvents: IEventObject,
  ongoingEvents: IEventObject,
  setUpcomingEvents?: Dispatch<SetStateAction<IEventObject>>,
  setOngoingEvents?: Dispatch<SetStateAction<IEventObject>>,
  activeTab: string,
  setActiveTab?: Dispatch<SetStateAction<string>>,
  loadedEvents: boolean,
  setLoadedEvents?: Dispatch<SetStateAction<boolean>>
}

export interface IEventObject {
  events: IEvent[],
  page: number,
  nextPage: null|number,
  limit: number
}

export const EventContext = createContext<IEventContext>({
  onGetEvents: () => {},
  onChangeActiveTab: () => {},
  upcomingEvents: {} as IEventObject,
  ongoingEvents: {} as IEventObject,
  activeTab: EEventType.UPCOMING,
  loadedEvents: false
});

export const defaultEvents = {
  events: [],
  page: 1,
  nextPage: null,
  limit: 10
}

export const EventTypeParam = {
  upcoming: "upcoming",
  ongoing: "ongoing",
}

const EventProvider = (props: PropsWithChildren) => {
  const { showLoading, hideLoading } = useLoading();
  const [loadedEvents, setLoadedEvents] = useState(false);
  const [upcomingEvents, setUpcomingEvents] = useState<IEventObject>(defaultEvents);
  const [ongoingEvents, setOngoingEvents] = useState<IEventObject>(defaultEvents);
  const [activeTab, setActiveTab] = useState<string>(EEventType.UPCOMING);

  const onGetEvents = (body: IRequestUpcomingEvents) => getEventsByDate(body);

  const onChangeActiveTab = async (tab: string) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    if (loadedEvents) {
      let body = {
        date: EventTypeParam.upcoming,
        limit: defaultEvents.limit,
        page: defaultEvents.page
      }
      if (activeTab === EEventType.UPCOMING) {
        body = {
          date: EventTypeParam.upcoming,
          limit: upcomingEvents.limit,
          page: upcomingEvents.page
        }
      } else if (activeTab === EEventType.ONGOING) {
        body = {
          date: EventTypeParam.ongoing,
          limit: ongoingEvents.limit,
          page: ongoingEvents.page
        }
      }
      (async () => await getEventsByDate(body))();
    }
  }, [activeTab])


  const getEventsByDate = async (body: IRequestUpcomingEvents) => {
    try {
      showLoading();
      const response = await fetchEventsByDate(body);
      if (response) {
        if (activeTab === EEventType.UPCOMING) {
          setUpcomingEvents({
            events: response.page > 1 ? [...upcomingEvents.events, ...response.docs] : response.docs,
            page: response.page,
            nextPage: response.nextPage,
            limit: response.limit
          });
        } else if (activeTab === EEventType.ONGOING) {
          setOngoingEvents({
            events: response.page > 1 ? [...ongoingEvents.events, ...response.docs] : response.docs,
            page: response.page,
            nextPage: response.nextPage,
            limit: response.limit
          });
        }
      }
    } catch (error: any) {
        handleServiceError(error);
    } finally {
      hideLoading();
    }
  }

  const contextValue = {
    onGetEvents,
    onChangeActiveTab,
    upcomingEvents,
    setUpcomingEvents,
    ongoingEvents,
    setOngoingEvents,
    activeTab,
    setActiveTab,
    loadedEvents,
    setLoadedEvents
  };

  return (
    <EventContext.Provider value={contextValue}>
      {props.children}
    </EventContext.Provider>
  );
};

export default EventProvider;
