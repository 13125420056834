import React, { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import OrderItem from "../../components/order-item/order-item";
import TitleComponent from "../../components/title/title";
import { SERVICE_FEE } from "../../constants";
import { ETheme } from "../../constants/theme";
import { ThemeContext } from "../../contexts/theme-context";
import { TicketContext } from "../../contexts/ticket-context";
import { fixDecimals } from "../../utils/helper";
import IconInfo from '../../assets/images/IconInfo.png'
import "./orders.scss";

export interface IOrdersProps { }

export default function Orders(props: IOrdersProps) {
  const { tickets, currency, serviceCostMaxFee } = useContext(TicketContext);
  const { theme } = useContext(ThemeContext);
  const { t } = useTranslation();

  const calculatorSubTotal = useMemo(() => {
    const subTotal = tickets.reduce(
      (acc, item) => acc + item.order * item.price,
      0
    );
    return subTotal;
  }, [tickets]);

  const calculatorTotal = useMemo(() => {
    const subTotal = calculatorSubTotal;
    return subTotal;
  }, [tickets]);

  const ticketOrder = useMemo(() => {
    const length = tickets.reduce((acc, item) => acc + item.order, 0);
    return length;
  }, [tickets]);

  return (
    <>
      <div className="order-title">
        <TitleComponent text={t("title.order")} />
      </div>
      <div className="order">
        <div className="list">
          {/* <div className={`header ${theme === ETheme.LIGHT && "header-light"}`}>
            <div>
              {ticketOrder > 1 ? translate.order.items : translate.order.item}
            </div>
            <div>
              {ticketOrder > 1
                ? translate.order.tickets
                : translate.order.ticket}
            </div>
          </div> */}
          <div className="tickets">
            {tickets
              .filter((item) => item.count > 0)
              .map((item) => (
                <OrderItem key={item._id} ticket={item} />
              ))}
          </div>
        </div>
        <div className="divider"></div>
        <div className="list-price">
          <div className="item-price">
            <div className={`label ${theme !== ETheme.DARK && "label-light"}`}>
              {t("order.subTotal")}
            </div>
            <div className={`price ${theme !== ETheme.DARK && "price-light"}`}>
              <span className="concurrency">{currency}</span>&nbsp;
              {fixDecimals(calculatorSubTotal)}
            </div>
          </div>

          {!!serviceCostMaxFee &&
          <div className="item-price">
            <div className="wrapperTooltip">
              <div className={`label ${theme !== ETheme.DARK && "label-light"}`}>
                {t("order.fee")}
              </div>
              <div className="tooltip">
                <img src={IconInfo} className="iconInfo" />
                <span className="tooltiptext">5% fee for Carbon offsetting
                  by planting mangroves</span>
              </div>
            </div>
            <div className={`price ${theme !== ETheme.DARK && "price-light"}`}>
              <span className="concurrency">{currency}</span>&nbsp;
              {fixDecimals(calculatorSubTotal * serviceCostMaxFee)}
            </div>
          </div> }

          <div className="item-price">
            <div
              className={`label ${theme !== ETheme.DARK && "label-light"}`}
            // style={{ fontWeight: 700 }}
            >
              {t("order.total")}
            </div>
            <div className={`price ${theme !== ETheme.DARK && "price-light"}`}>
              <span className="concurrency">{currency}</span>&nbsp;
              {fixDecimals(calculatorTotal * (1 + serviceCostMaxFee))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
