import React, {useContext} from "react";
import { useTranslation } from "react-i18next";
import {IEvent} from "../../apis/types";
import { ETheme } from "../../constants/theme";
import { ThemeContext } from "../../contexts/theme-context";
import "./item-event.scss";
import moment from "moment-timezone";

export interface IItemEventProps {
  event: IEvent;
}

export default function ItemEvent(props: IItemEventProps) {
  const { event } = props;
  const { theme } = useContext(ThemeContext);
  const { t } = useTranslation();

  return (
    <div className="item-event" onClick={() => window.location.href = event.url}>
      <div className="event-image">
        <div className="image-content">
            <img className="image" src={event.logoUrl} />
        </div>
      </div>
        <div className="event-information">
            <div className="top-content">
                <div
                    className={`eventName ${
                        theme !== ETheme.DARK && "eventName-theme"
                    }`}
                >
                    {event.name}
                </div>
                {/*{renderPriceTicket}*/}
            </div>
            <div className="bottom-content">
                <div
                    className={`description ${
                        theme !== ETheme.DARK && "description-light"
                    }`}
                >
                    {event.address}
                </div>
                <div
                    className={`description ${
                        theme !== ETheme.DARK && "description-light"
                    }`}
                >
                    {moment(event.startDate).format("HH:mm, MM-DD-YYYY")} / {moment(event.endDate).format("HH:mm, MM-DD-YYYY")}
                </div>
            </div>
        </div>
    </div>
  );
}
