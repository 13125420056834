import { createContext, PropsWithChildren, useState } from "react";
import { useTranslation } from "react-i18next";
import { fetchTicketEventByUrl } from "../apis";
import {
  EValueLanguage,
  ITicket,
  ITicketDefinition,
  ITicketResponse,
  IEventDetail,
  EChangeCountTicket,
} from "../apis/types";
import { SYMBOL_CURRENCY } from "../constants/currency";
import { useLoading } from "../hooks/useLoading";
import useToast, { EToast } from "../hooks/useToast";
import handleServiceError, {
  nameByLang,
  symbolCurrency,
} from "../utils/helper";

export interface ITicketContext {
  onGetTicket: (url: string) => void;
  tickets: Array<ITicket>;
  event: IEventDetail;
  currency: SYMBOL_CURRENCY;
  serviceCostMaxFee: number;
  termconditionUrl: string;
  handleChangeCountTicket: (
    id: string,
    auto?: EChangeCountTicket,
    value?: number
  ) => void;
}

export const TicketContext = createContext<ITicketContext>({
  onGetTicket: () => {},
  tickets: [],
  event: {} as IEventDetail,
  currency: SYMBOL_CURRENCY.EUR,
  serviceCostMaxFee: 0,
  termconditionUrl: "",
  handleChangeCountTicket: () => {},
});

const TicketProvider = (props: PropsWithChildren) => {
  const { showLoading, hideLoading } = useLoading();
  const [tickets, setTickets] = useState<Array<ITicket>>([]);
  const [event, setEvent] = useState<IEventDetail>({} as IEventDetail);
  const [currency, setCurrency] = useState<SYMBOL_CURRENCY>(
    SYMBOL_CURRENCY.EUR
  );
  const [serviceCostMaxFee, setServiceCostMaxFee] = useState<number>(0);
  const [termconditionUrl, setTermconditionUrl] = useState<string>("https://cdn.chi.app/termcondition.pdf");
  const addToast = useToast();
  const { t } = useTranslation();

  const onGetTicket = (url: string) => {
    return getTicketByUrl(url);
  };

  const getTicketByUrl = async (url: string) => {
    try {
      showLoading();
      const response = await fetchTicketEventByUrl(url);
      console.log("Response==", response);
      if (
        !response ||
        !response.ticketDefinitions ||
        response.ticketDefinitions.length === 0
      )
        return addToast(EToast.ERROR, t("toast.emptyTicket"));
      const event = response.event;
      const symbol = symbolCurrency(event.currency);
      setCurrency(symbol);
      setEvent(event);
      if (response.termConditionUrl) {
        setTermconditionUrl(response.termConditionUrl);
      }
      setServiceCostMaxFee(response.serviceCostMaxFee);
      handleTicket(response);
    } catch (error: any) {
      if (error.response.data.Error == "Event not found ") {
        throw error;
      } else {
        handleServiceError(error);
      }
    } finally {
      hideLoading();
    }
  };

  const handleTicket = (data: ITicketResponse) => {
    let listTicket: Array<ITicket> = [];
    data.ticketDefinitions.map((item) => {
      if (!item.phases) {
        const ticket = handleTicketOnePhase(item);
        listTicket.push(ticket);
      } else {
        const tickets: Array<ITicket> = handleTicketMultiPhase(
          item
        ) as Array<ITicket>;
        listTicket = [...listTicket, ...tickets];
      }
    });
    setTickets(listTicket);
  };

  const handleTicketOnePhase = (ticket: ITicketDefinition) => {
    const {
      _id,
      startDateSale,
      endDateSale,
      maximumTicketsPerCustomer,
      totalTickets,
      name,
      description,
      price,
      numOfLefTickets,
      isAddOn,
    } = ticket;
    const data: ITicket = {
      _id,
      startDateSale,
      endDateSale,
      maximumTicketsPerCustomer,
      totalTickets,
      name: nameByLang(name, EValueLanguage.NAME),
      description: nameByLang(description, EValueLanguage.DESCRIPTION),
      price,
      count: 0,
      order: 0,
      numOfLefTickets,
      isAddOn,
    };
    return data;
  };
  const handleTicketMultiPhase = (ticket: ITicketDefinition) => {
    const data = ticket.phases?.map((item) => {
      const {
        _id,
        startDateSale,
        endDateSale,
        maximumTicketsPerCustomer,
        numberOfTicket,
        price,
        name,
        description,
        numOfLefTickets,
      } = item;
      const body: ITicket = {
        _id,
        startDateSale,
        endDateSale,
        maximumTicketsPerCustomer,
        totalTickets: numberOfTicket,
        price,
        name: name || "Regular tickets",
        description: description || "",
        count: 0,
        order: 0,
        numOfLefTickets,
      };
      return body;
    });
    return data;
  };

  const handleFindTicketById = (id: string) => {
    const ticket = tickets.find((item) => item._id === id);
    return ticket as ITicket;
  };

  const handleChangeCountTicket = (
    id: string,
    auto?: EChangeCountTicket,
    value?: number
  ) => {
    const ticket = handleFindTicketById(id);
    if (auto) {
      let newCount: number;
      if (auto === EChangeCountTicket.INCREMENT) {
        newCount =
          ticket.count + 1 > ticket.numOfLefTickets
            ? ticket.numOfLefTickets
            : ticket.count + 1;
      } else {
        newCount = ticket.count - 1 > 0 ? ticket.count - 1 : 0;
      }
      if (newCount > ticket.maximumTicketsPerCustomer) {
        return addToast(
          EToast.ERROR,
          `Purchase limited to ${ticket.maximumTicketsPerCustomer} ${ticket.name} per account`
        );
      }
      const newTickets = tickets.map((item) =>
        item._id === ticket._id
          ? {
              ...item,
              count: newCount,
              order: newCount,
            }
          : { ...item }
      );
      setTickets(newTickets);
      return;
    }
    if (value || value === 0) {
      const newTickets = tickets.map((item) =>
        item._id === ticket._id
          ? {
              ...item,
              order: value,
              count: value === 0 ? 0 : item.count,
            }
          : { ...item }
      );
      setTickets(newTickets);
      return;
    }
  };

  const contextValue = {
    onGetTicket,
    tickets,
    event,
    handleChangeCountTicket,
    currency,
    serviceCostMaxFee,
    termconditionUrl
  };

  return (
    <TicketContext.Provider value={contextValue}>
      {props.children}
    </TicketContext.Provider>
  );
};

export default TicketProvider;
