import { CardType } from "../constants/card-type";
import { CURRENCY } from "../constants/currency";
import { ELanguage } from "../constants/language";

export interface ITokenResponse {
  access_token: string;
  refresh_token: string;
}

export interface IEventDetail {
  _id: string;
  collectorWalletSigners: Array<string>;
  eventType: Array<string>;
  tags: Array<string>;
  imageUrls: Array<string>;
  topupOptions: Array<string>;
  crews: Array<string>;
  teams: Array<string>;
  currency: CURRENCY;
  startDate: string;
  endDate: string;
  address: string;
  address2: string;
  state: string;
  country: string;
  city: string;
  countryCode: string;
  logoUrl?: string;
  status: string;
  ticketProvider: string;
  name: string;
  postalCode: string;
  location: {
    _id: string;
    latitude: number;
    longitude: number;
  };
  maxVisitors: number;
  expectedVisitors: number;
  creator: string;
  organization: string;
  collectorWallet: string;
  createdAt: string;
  updatedAt: string;
  url: string;
  tagline?: string;
  description?: string;
  __v: 0;
}

export interface ITicketResponse {
  ticketDefinitions: Array<ITicketDefinition>;
  soldTickets: {
    [key: string]: number;
  };
  event: IEventDetail;
  theme?: string,
  serviceCostMaxFee: number;
  termConditionUrl?: string;
}

export interface ITicketDefinition {
  _id: string;
  zoneAccess: Array<string>;
  gateAccess: Array<string>;
  maxReservable: number;
  type: string;
  name: Array<ILanguage>;
  description: Array<ILanguage>;
  isAddOn: boolean;
  requiredVisitorFullName: boolean;
  requiredVisitorPhoneNumber: boolean;
  requiredVisitorEmail: boolean;
  hideonEventX: boolean;
  hideOnWebsite: boolean;
  disableMarketPlace: boolean;
  charged: boolean;
  validity: string;
  maximumTicketsPerCustomer: number;
  startDate: string;
  endDate: string;
  startDateSale: string;
  endDateSale: string;
  event: IEventDetail;
  totalTickets: number;
  creator: string;
  createdAt: string;
  updatedAt: string;
  __v: 0;
  numOfLefTickets: number;
  price: number;
  phases?: Array<IPhaseTicket>;
  isHot: boolean;
  isTrend: boolean;
  isNew: boolean;
}

export interface IPhaseTicket {
  _id: string;
  maximumTicketsPerCustomer: number;
  startDateSale: string;
  endDateSale: string;
  numberOfTicket: number;
  price: number;
  ticketDefinitionId: string;
  name?: string;
  description?: string;
  __v: 0;
  createdAt: string;
  updatedAt: string;
  numOfLefTickets: number;
}

export interface ILanguage {
  lang: ELanguage;
  content: string;
}

export enum EValueLanguage {
  NAME = "NAME",
  DESCRIPTION = "DESCRIPTION",
}

export enum EChangeCountTicket {
  INCREMENT = "INCREMENT",
  DECREMENT = "DECREMENT",
}

export interface ITicket {
  _id: string;
  startDateSale: string;
  endDateSale: string;
  maximumTicketsPerCustomer: number;
  totalTickets: number;
  name: string;
  description: string;
  price: number;
  count: number;
  order: number;
  numOfLefTickets: number;
  isAddOn?: boolean;
}

export interface IAccount {
  phone: string;
  email: string;
}

export interface IItemLockTicket {
  ticketDefinitionId: string;
  amount: number;
}

export interface IRequestPurchaseTicket {
  tickets: Array<IItemLockTicket>;
  email: string;
  phone: string;
  eventId: string;
  answers?: Array<any>;
}

export interface IResponsePurchaseTicket {
  url: string;
}

export interface IRequestLocketTicket {
  ticketInfoList: Array<IItemLockTicket>;
  email: string;
  phone: string;
}

export interface IResponseLockTicket {
  reserved: Array<string>;
  amount: number;
  ticketDefinitionId: string;
}

export interface IRequestTicketFiat {
  ticketIds: Array<string>;
  email: string;
  phone: string;
  cardType: CardType;
}

export interface IResponseFiatTicket {
  PaymentUrl: string;
  hash: string;
}

export interface IRequestUpcomingEvents {
  date: string,
  page: number,
  limit: number
}

export interface IEvent {
  _id: string,
  name: string,
  address: string,
  venue: string,
  startDate: string,
  endDate: string,
  imageUrls: Array<string>,
  url: string,
  logoUrl: string
}

export interface IResponseUpcomingEvents {
  docs: IEvent[],
  page: number,
  limit: number,
  nextPage: null|number
}
